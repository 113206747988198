import React from 'react';
import { NewHomepage } from '@/features/new-landing-page/pages/homepage';
import { FirstPromoterScript } from '@/components/first-promoter-script';

export default NewHomepage;

export const Head = () => (
  <>
    <link
      href="https://assets.calendly.com/assets/external/widget.css"
      rel="stylesheet"
    />
    <script
      type="text/javascript"
      src="https://assets.calendly.com/assets/external/widget.js"
      async
    />
    <script
      type="text/javascript"
      src="https://app.sprintful.com/widget/v1.js"
    />
    <FirstPromoterScript />
  </>
);
