import React from 'react';

export const FirstPromoterScript = () => (
  <>
    <script src="https://cdn.firstpromoter.com/fpr.js" />
    <script>
      {`(function (w) {
    w.fpr = w.fpr || function () {
        w.fpr.q = w.fpr.q || [];
        w.fpr.q[arguments[0] == 'set' ? 'unshift' : 'push'](arguments);
    }
})(window)
fpr("init", {cid: "sw6kbbih"});
fpr("click");`}
    </script>
  </>
);
